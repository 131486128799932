import { FC, useState } from "react";

import _ from "lodash";

import Grid from "@mui/material/Grid";

import { FaqField } from "./FaqField";

import { FAQ, FAQData } from "./interfaces";

export interface FaqProps {
  value: FAQData;
  onChange: (newValue: FAQ[]) => void;
}

export const Faq: FC<FaqProps> = ({ value: values, onChange }) => {
  const [currentIndexFaqs, setCurrentIndexFaqs] = useState<number | null>(null);
  const [showAddFaqItemButton, setShowAddFaqItemButton] =
    useState<boolean>(false);
  const [faqs, setFaqs] = useState<FAQ[]>(values.faqs);

  const saveFaqs = (index: number, title: string, description: string) => {
    const _faqs = _.cloneDeep(faqs);
    _faqs.splice(index, 1, { title, description });

    setFaqs(_faqs);

    onChange(_faqs);

    setCurrentIndexFaqs(null);
    setShowAddFaqItemButton(false);
  };

  const deleteFaqs = (index: number) => {
    const _faqs = _.cloneDeep(faqs);
    _faqs.splice(index, 1);

    setFaqs(_faqs);

    onChange(_faqs);
  };

  const addFaqs = () => {
    const newFaq = {
      title: "",
      description: "",
    };
    const _faqs = _.cloneDeep(faqs);
    _faqs.push(newFaq);

    setFaqs(_faqs);

    setCurrentIndexFaqs(_faqs.length - 1);
    setShowAddFaqItemButton(true);
  };

  return (
    <Grid container component="form" spacing={3}>
      <Grid item xs={12}>
        <FaqField
          faqs={faqs}
          currentIndexFaqs={currentIndexFaqs}
          showAddFaqItemButton={showAddFaqItemButton}
          onHandleSaveFaqs={saveFaqs}
          onHandleDeleteFaqs={deleteFaqs}
          onHandleAddFaqs={addFaqs}
          onHandleShowAddFaqItemButton={setShowAddFaqItemButton}
          onHandleCurrentIndexFaqs={setCurrentIndexFaqs}
        />
      </Grid>
    </Grid>
  );
};
