import { FC, useCallback, useState, useEffect } from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveIcon from "@mui/icons-material/Save";
import TimesIcon from "@mui/icons-material/ClearRounded";

export interface EditProps {
  data: {
    title: string;
    description: string;
  };
  index: number;
  onHandleShowAddFaqItemButton: (state: any) => void;
  onHandleCurrentIndexFaqs: (index: number | null) => void;
  onHandleSave: (index: number, question: string, description: string) => void;
  onHandleDelete: (index: number) => void;
}

export const Edit: FC<EditProps> = ({
  data,
  index,
  onHandleShowAddFaqItemButton,
  onHandleCurrentIndexFaqs,
  onHandleSave,
  onHandleDelete,
}) => {
  const [answear, setAnswear] = useState(data.description);
  const [question, setQuestion] = useState(data.title);
  const [isNoValid, setIsNoValid] = useState(
    data.description === "" || data.title === "",
  );

  useEffect(() => {
    setIsNoValid(answear === "" || question === "");
  }, [answear, question]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Stack spacing={2} width="100%" marginRight="30px">
        <TextareaAutosize
          style={{ background: "transparent", padding: "15px", color: "grey" }}
          aria-label="empty textarea"
          placeholder="Question"
          value={question}
          defaultValue={question}
          onChange={(e) => setQuestion(e.target.value)}
        />

        <TextareaAutosize
          style={{ background: "transparent", padding: "15px", color: "grey" }}
          aria-label="empty textarea"
          placeholder="Answear"
          value={answear}
          defaultValue={answear}
          onChange={(e) => setAnswear(e.target.value)}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{ justifyContent: "center", marginTop: "20px" }}
      >
        <Tooltip title="Cancel">
          <IconButton
            onClick={() => {
              isNoValid && onHandleDelete(index);
              onHandleCurrentIndexFaqs(null);
              onHandleShowAddFaqItemButton(false);
            }}
            color="error"
            size="small"
          >
            <TimesIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Save">
          <IconButton
            onClick={() => onHandleSave(index, question, answear)}
            disabled={isNoValid}
            color="success"
            size="small"
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};
