import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'

import { SeoFields } from './forms/seo-fields'

export const STEPS = [
  {
    slug: SEO_FIELDS_SLUG,
    Component: SeoFields,
  },
] as const
