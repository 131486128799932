import { Dashboard } from './Dashboard'
import { MetaTags } from './meta-tags';
import { Pages } from './pages';

export const MainSection = () => (
  <>
    <Dashboard />
    <MetaTags />
    <Pages />
  </>
)
