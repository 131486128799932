import { FC, useMemo, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { NewsMetaCard } from "./news-meta-card";
import { Add } from "common/AllPages/actions/add";

export interface DisplayProps {
  data: any;
}

export const Display: FC<DisplayProps> = ({ data }) => {
  const [newsMeta, setNewsMeta] = useState(data?.data);
  const filteredData = useMemo(() => {
    return newsMeta.filter((item: any) => item.seo_fields.slug === "news") || [];
  }, [newsMeta]);

  if (filteredData.length === 0) return <Add pathToAdd="/meta/news/create" />;

  return (
    <List aria-label="Page list">
      {filteredData.map((item: any) => {
        return (
          <>
            <NewsMetaCard
              newsMetaData={item}
              newsMeta={newsMeta}
              setNewsMeta={setNewsMeta}
            />
            <Divider />
          </>
        );
      })}
    </List>
  );
};
