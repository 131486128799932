import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'
import { GEO_SLUG, PROGRAM_CARD_SLUG } from './constants'

import { SeoFields } from './forms/seo-fields'
import { WYSIWYG } from './forms/WYSIWYG'
import { ProgramCard } from './forms/program-card'
import { Geo } from './forms/geo'
import { FAQ_SLUG } from 'common/Faq/constants'
import { Faq } from './forms/faqs'

export const STEPS = [
  {
    slug: SEO_FIELDS_SLUG,
    Component: SeoFields,
  },
  {
    slug: PROGRAM_CARD_SLUG,
    Component: ProgramCard,
  },
  {
    slug: GEO_SLUG,
    Component: Geo,
  },
  {
    slug: FAQ_SLUG,
    Component: Faq,
  },
  {
    slug: WYSIWYG_SLUG,
    Component: WYSIWYG,
  },
] as const
