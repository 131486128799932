import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import type { DataSet } from './types'

import { initialSEOData } from 'common/SeoFields/interfaces'
import { initialWYSIWYGData } from 'common/WYSIWYG/interfaces'
import { initialBaseCardData } from './forms/base-card/interfaces'
import { BASE_CARD_SLUG } from './constants'
import { initialFAQData } from 'common/Faq/interfaces'
import { FAQ_SLUG } from 'common/Faq/constants'

export const initialDataSet: DataSet = {
  [SEO_FIELDS_SLUG]: initialSEOData,
  [BASE_CARD_SLUG]: initialBaseCardData,
  [FAQ_SLUG]: initialFAQData,
  [WYSIWYG_SLUG]: initialWYSIWYGData,
}
