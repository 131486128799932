import { useMemo } from 'react'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'

export function useProgramMetaData(state: any, isPut: boolean = false) {
  return useMemo(() => {
    const data = {
      'seo_fields': state[SEO_FIELDS_SLUG],
    }

    data['seo_fields']['slug'] = 'partnership-programs';
    
    // @ts-ignore
    if (isPut) data['_method'] = 'PUT'

    return data
  }, [state])
}