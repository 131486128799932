import { FC } from "react";

import * as APIv1 from "api/v1";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { Container } from "layout/Container";

import { Header } from "common/Header";

import { BasesMetaLoader } from "./bases-meta-loader";

export interface BasesMetaProps {}

export const BasesMeta: FC<BasesMetaProps> = () => {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Box>
              <Header>Bases</Header>
            </Box>
            <Box sx={{ paddingTop: "10px" }}>
              <APIv1.Get url="/general-pages">{BasesMetaLoader}</APIv1.Get>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
