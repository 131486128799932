import React from "react";

import { createSnackbar } from "utils/createSnackbar";

import instance from "api/v1";
import { useAuth } from "hooks/useAuth";

export function useDeleteNewsMeta() {
  // @ts-ignore
  const { user } = useAuth();
  const apiInstance = instance(user);

  return React.useCallback(
    (newsMetaId: number, newsMeta: [], setNewsMeta: any) => {
      const fetch = async () => {
        await apiInstance
          .delete(`/general-pages/${newsMetaId}`)
          .then(() => {
            createSnackbar("News meta tags deleted successfully");
            setNewsMeta(newsMeta.filter((item: any) => item.id !== newsMetaId));
          })
          .catch((error) => {
            console.error(error);
            if (error.response) createSnackbar(error.response.data.message);
            else createSnackbar("Something went wrong");
          });
      };
      fetch();
    },
    [],
  );
}
