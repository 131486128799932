export type FAQ = {
  id?: number;
  title: string;
  description: string;
};

export interface FAQData {
  faqs: FAQ[];
}

export const initialFAQData: FAQData = {
  faqs: [],
};
