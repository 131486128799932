import { FC } from "react";
import { get } from "lodash";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useDeleteBasesMeta } from "./hooks/useDeleteBasesMeta";
import { useModalHandler } from "hooks/useModalHandler";
import { ConfirmationModal } from "common/ConfirmationModal";
import { Edit } from "common/AllPages/actions/edit";
import { Delete } from "common/AllPages/actions/delete";

export interface BasesMetaCardProps {
  basesMetaData: any;
  basesMeta: [];
  setBasesMeta: any;
}

export const BasesMetaCard: FC<BasesMetaCardProps> = ({
  basesMetaData,
  basesMeta,
  setBasesMeta,
}) => {
  const [isOpen, openModal, closeModal] = useModalHandler();
  const deleteBasesMeta = useDeleteBasesMeta();
  const basesMetaId = get(basesMetaData, "id", "");

  return (
    <ListItem>
      <Box>
        <Typography variant="body2" color="text.primary">
          <b>Page meta tags</b>
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Edit pageId={basesMetaId} pathToEdit="meta/bases" />
          {/* @ts-ignore */}
          <Delete clickHandler={openModal} />
        </Box>
      </Box>
      {isOpen && (
        <ConfirmationModal
          // @ts-ignore
          isModalOpen={isOpen}
          // @ts-ignore
          closeModal={closeModal}
          confirmHandler={() => {
            deleteBasesMeta(basesMetaId, basesMeta, setBasesMeta);
            // @ts-ignore
            closeModal();
          }}
          message={`Are you sure you want to remove the bases page meta tags?`}
        />
      )}
    </ListItem>
  );
};
