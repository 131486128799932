import { Routes, Route } from 'react-router-dom'

import { LoginLayout } from 'components/Template/LoginLayout'
import { ProtectedLayout } from 'components/Template/ProtectedLayout'

import Login from './login'
import { Index } from './home'
import { HomeMeta } from './all-meta/home'
import { CreateHomeMeta } from './single-meta/home/create'
import { EditHomeMeta } from './single-meta/home/edit'
import { NewsMeta } from './all-meta/news'
import { CreateNewsMeta } from './single-meta/news/create'
import { EditNewsMeta } from './single-meta/news/edit'
import { BasesMeta } from './all-meta/bases'
import { CreateBaseMeta } from './single-meta/base/create'
import { EditBaseMeta } from './single-meta/base/edit'
import { ProgramsMeta } from './all-meta/programs'
import { CreateProgramMeta } from './single-meta/program/create'
import { EditProgramMeta } from './single-meta/program/edit'
import { AllNews } from './all-news'
import { CreateNews } from './single-news/create'
import { EditNews } from './single-news/edit'
import { AllBases } from './all-bases'
import { AllPrograms } from './all-programs'
import { CreateBase } from './single-base/create'
import { EditBase } from './single-base/edit'
import { CreateProgram } from './single-program/create'
import { EditProgram } from './single-program/edit'

export const Router = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path="login" element={<Login />} />
    </Route>
    <Route path="/" element={<ProtectedLayout />}>
      <Route index element={<Index />} />
      <Route path="meta">
        <Route path="home">
          <Route index element={<HomeMeta />} />
          <Route path="create" element={<CreateHomeMeta />} />
          <Route path=":homeMetaId">
            <Route path="edit" element={<EditHomeMeta />} />
          </Route>
        </Route>
        <Route path="news">
          <Route index element={<NewsMeta />} />
          <Route path="create" element={<CreateNewsMeta />} />
          <Route path=":newsMetaId">
            <Route path="edit" element={<EditNewsMeta />} />
          </Route>
        </Route>
        <Route path="bases">
          <Route index element={<BasesMeta />} />
          <Route path="create" element={<CreateBaseMeta />} />
          <Route path=":baseMetaId">
            <Route path="edit" element={<EditBaseMeta />} />
          </Route>
        </Route>
        <Route path="programs">
          <Route index element={<ProgramsMeta />} />
          <Route path="create" element={<CreateProgramMeta />} />
          <Route path=":programMetaId">
            <Route path="edit" element={<EditProgramMeta />} />
          </Route>
        </Route>
      </Route>
      <Route path="news">
        <Route index element={<AllNews />} />
        <Route path="create" element={<CreateNews />} />
        <Route path=":newsId">
          <Route path="edit" element={<EditNews />} />
        </Route>
      </Route>
      <Route path="bases">
        <Route index element={<AllBases />} />
        <Route path="create" element={<CreateBase />} />
        <Route path=":baseId">
          <Route path="edit" element={<EditBase />} />
        </Route>
      </Route>
      <Route path="programs">
        <Route index element={<AllPrograms />} />
        <Route path="create" element={<CreateProgram />} />
        <Route path=":programId">
          <Route path="edit" element={<EditProgram />} />
        </Route>
      </Route>
    </Route>
  </Routes>
)
