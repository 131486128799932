import { useState, useCallback } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CodeIcon from '@mui/icons-material/Code';

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Collapse from "@mui/material/Collapse";

import { Home } from "./Home";
import { News } from "./News";
import { Bases } from "./Bases";
import { Programs } from "./Programs";

export const MetaTags = () => {
  const [open, setOpen] = useState(true);
  const handleToggle = useCallback(() => setOpen((open) => !open), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <div>
      <ListItemButton id="demo-customized-button" onClick={handleToggle}>
        <ListItemIcon>
          <CodeIcon />
        </ListItemIcon>
        <ListItemText primary="Meta tags" />
        <KeyboardArrowDownIcon />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ClickAwayListener onClickAway={handleClose}>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            <Home />
            <News />
            <Bases />
            <Programs />
          </List>
        </ClickAwayListener>
      </Collapse>
    </div>
  );
};
