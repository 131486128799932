import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { set } from 'monolite'
import type { AxiosError } from 'axios'
import _get from 'lodash/get'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { SEOData } from 'common/SeoFields/interfaces'
import { WYSIWYGData } from 'common/WYSIWYG/interfaces'

import { DataSet } from '../../types'
import { ProgramCardData } from 'pages/single-program/forms/program-card/interfaces'
import { REF_LINK, TEXT, TITLE } from 'pages/single-program/forms/program-card/constants'
import { GEO_SLUG, PROGRAM_CARD_SLUG } from 'pages/single-program/constants'
import { GEOData } from 'common/Geo/interfaces'
import { FAQ_SLUG } from 'common/Faq/constants'
import { FAQData } from 'common/Faq/interfaces'

export const useFetchProgram = (setState: Dispatch<SetStateAction<DataSet>>) => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  const navigate = useNavigate()
  const { programId } = useParams<{ programId: string }>()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await apiInstance.get(`/programs/${programId}`)

        const seoData: SEOData = {
          title: _get(data, 'data.seo_fields.title', ''),
          description: _get(data, 'data.seo_fields.description', ''),
          h1: _get(data, 'data.seo_fields.h1', ''),
          micromarking: _get(data, 'data.seo_fields.micromarking', ''),
          slug: _get(data, 'data.seo_fields.slug', ''),
        }

        const programData: ProgramCardData = {
          image: _get(data, 'data.image_path', ''),
          image_path: _get(data, 'data.image_path', ''),
          [TITLE]: _get(data, `data.${[TITLE]}`, ''),
          [TEXT]: _get(data, `data.${[TEXT]}`, ''),
          [REF_LINK]: _get(data, `data.${[REF_LINK]}`, ''),
        }

        const geoData: GEOData = {
          geos: _get(data, 'data.geos', []),
        }

        const faqData: FAQData = {
          faqs: _get(data, "data.faqs", []),
        }

        const wysiwygData: WYSIWYGData = {
          content: _get(data, 'data.body', '')
        }

        setState((state) => set(state, _ => _[SEO_FIELDS_SLUG], seoData))
        setState((state) => set(state, _ => _[PROGRAM_CARD_SLUG], programData))
        setState((state) => set(state, _ => _[GEO_SLUG], geoData))
        setState((state) => set(state, _ => _[FAQ_SLUG], faqData))
        setState((state) => set(state, _ => _[WYSIWYG_SLUG], wysiwygData))
      } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
          navigate('/', { replace: true })
          return
        }

        console.error(error)
      }
    })()
  }, [navigate, setState])
}
