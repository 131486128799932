import { useMemo } from 'react'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'
import { NEWS_CARD_SLUG } from '../constants'
import { FAQ_SLUG } from 'common/Faq/constants'

export function useNewsData(state: any, isPut: boolean = false) {
  return useMemo(() => {
    const data = {
      'type': 'news',
      'seo_fields': state[SEO_FIELDS_SLUG],
      ...state[NEWS_CARD_SLUG],
      [FAQ_SLUG]: JSON.stringify(state[FAQ_SLUG][FAQ_SLUG]),
      'body': state[WYSIWYG_SLUG][WYSIWYG_SLUG],
    }
    
    // @ts-ignore
    if (isPut) data['_method'] = 'PUT'

    return data
  }, [state])
}