import { FC, useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import HomeIcon from "@mui/icons-material/Home";

const PATH = '/meta/news'

export const News: FC<{
  onClick?: () => void
}> = ({ onClick }) => {
  const navigate = useNavigate()
  const match = useMatch(PATH)
  const handleClick = useCallback(() => {
    navigate(PATH)

    onClick && onClick()
  }, [navigate, onClick])

  return (
    <ListItemButton onClick={handleClick} selected={Boolean(match)}>
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="News" />
    </ListItemButton>
  )
}
