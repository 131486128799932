import { FC, useCallback } from "react";

import { set } from "monolite";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { FAQ_SLUG } from "common/Faq/constants";

import { Faq as CFaq } from "common/Faq";
import { FAQ } from "common/Faq/interfaces";
import type { StepViewProps } from "../../types";

export interface FaqProps extends StepViewProps<typeof FAQ_SLUG> {}

export const Faq: FC<FaqProps> = ({ value: values, slug, onChange }) => {
  const handelChange = useCallback(
    (newValue: FAQ[]) => {
      onChange(
        set(values, (_) => _.faqs, newValue),
        slug
      );
    },
    [onChange, slug, values]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h1" variant="h4" align="center">
          FAQ
        </Typography>
      </Box>
      <Divider sx={{ mx: { xs: -2, md: -3 }, my: { xs: 2, md: 3 } }} />
      <CFaq value={values} onChange={handelChange} />
    </>
  );
};
