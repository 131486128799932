import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import { SeoFields } from './forms/seo-fields'
import { WYSIWYG } from './forms/WYSIWYG'
import { BASE_CARD_SLUG } from './constants'
import { BaseCard } from './forms/base-card'
import { FAQ_SLUG } from 'common/Faq/constants'
import { Faq } from './forms/faqs'

export const STEPS = [
  {
    slug: SEO_FIELDS_SLUG,
    Component: SeoFields,
  },
  {
    slug: BASE_CARD_SLUG,
    Component: BaseCard,
  },
  {
    slug: FAQ_SLUG,
    Component: Faq,
  },
  {
    slug: WYSIWYG_SLUG,
    Component: WYSIWYG,
  },
] as const
