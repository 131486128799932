import { FC } from "react";
import { get } from "lodash";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useDeleteProgramsMeta } from "./hooks/useDeleteProgramsMeta";
import { useModalHandler } from "hooks/useModalHandler";
import { ConfirmationModal } from "common/ConfirmationModal";
import { Edit } from "common/AllPages/actions/edit";
import { Delete } from "common/AllPages/actions/delete";

export interface ProgramsMetaCardProps {
  programsMetaData: any;
  programsMeta: [];
  setProgramsMeta: any;
}

export const ProgramsMetaCard: FC<ProgramsMetaCardProps> = ({
  programsMetaData,
  programsMeta,
  setProgramsMeta,
}) => {
  const [isOpen, openModal, closeModal] = useModalHandler();
  const deleteProgramsMeta = useDeleteProgramsMeta();
  const programsMetaId = get(programsMetaData, "id", "");

  return (
    <ListItem>
      <Box>
        <Typography variant="body2" color="text.primary">
          <b>Page meta tags</b>
        </Typography>
      </Box>
      <Box sx={{ ml: "auto" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Edit pageId={programsMetaId} pathToEdit="meta/programs" />
          {/* @ts-ignore */}
          <Delete clickHandler={openModal} />
        </Box>
      </Box>
      {isOpen && (
        <ConfirmationModal
          // @ts-ignore
          isModalOpen={isOpen}
          // @ts-ignore
          closeModal={closeModal}
          confirmHandler={() => {
            deleteProgramsMeta(programsMetaId, programsMeta, setProgramsMeta);
            // @ts-ignore
            closeModal();
          }}
          message={`Are you sure you want to remove the programs page meta tags?`}
        />
      )}
    </ListItem>
  );
};
