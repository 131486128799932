import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { set } from 'monolite'
import type { AxiosError } from 'axios'
import _get from 'lodash/get'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { SEOData } from 'common/SeoFields/interfaces'

import { DataSet } from '../../types'

export const useFetchNewsMeta = (setState: Dispatch<SetStateAction<DataSet>>) => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  const navigate = useNavigate()
  const { newsMetaId } = useParams<{ newsMetaId: string }>()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await apiInstance.get(`/general-pages/${newsMetaId}`)

        const seoData: SEOData = {
          title: _get(data, 'data.seo_fields.title', ''),
          description: _get(data, 'data.seo_fields.description', ''),
          h1: _get(data, 'data.seo_fields.h1', ''),
          micromarking: _get(data, 'data.seo_fields.micromarking', ''),
        }

        setState((state) => set(state, _ => _[SEO_FIELDS_SLUG], seoData))
      } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
          navigate('/', { replace: true })
          return
        }

        console.error(error)
      }
    })()
  }, [navigate, setState])
}
