import * as APIv1 from "api/v1";
import { createSnackbar } from "utils/createSnackbar";

import { Placeholder } from "common/AllPages/placeholder";
import { Display } from "./display";

export const HomeMetaLoader: APIv1.RequestProps["children"] = (
  _error,
  response,
  isLoading,
) => {
  const data = response?.data || [];
  const status = response?.status || null;

  if (isLoading) return <Placeholder label="Home meta tags" />;
  
  if (_error) {
    createSnackbar(_error.message);
  }

  if (status !== 200) return null;

  return <Display data={data} />;
};
