import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { createSnackbar } from 'utils/createSnackbar'

export const useCreateNewsMeta = () => {
  const navigate = useNavigate()
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)

  return useCallback((state: any) => {
    const fetch = async () => {
      await apiInstance.post('/general-pages', state, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(() => {
          createSnackbar('News page meta tags created successfully')
          navigate('/meta/news')
        })
        .catch((error) => {
          console.error(error)
          if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }, [])
}
