import { FC, useMemo, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { ProgramsMetaCard } from "./programs-meta-card";
import { Add } from "common/AllPages/actions/add";

export interface DisplayProps {
  data: any;
}

export const Display: FC<DisplayProps> = ({ data }) => {
  const [programsMeta, setProgramsMeta] = useState(data?.data);
  const filteredData = useMemo(() => {
    return programsMeta.filter((item: any) => item.seo_fields.slug === "partnership-programs") || [];
  }, [programsMeta]);

  if (filteredData.length === 0) return <Add pathToAdd="/meta/programs/create" />;

  return (
    <List aria-label="Page list">
      {filteredData.map((item: any) => {
        return (
          <>
            <ProgramsMetaCard
              programsMetaData={item}
              programsMeta={programsMeta}
              setProgramsMeta={setProgramsMeta}
            />
            <Divider />
          </>
        );
      })}
    </List>
  );
};
