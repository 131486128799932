import { FC, useMemo, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { HomeMetaCard } from "./home-meta-card";
import { Add } from "common/AllPages/actions/add";

export interface DisplayProps {
  data: any;
}

export const Display: FC<DisplayProps> = ({ data }) => {
  const [homeMeta, setHomeMeta] = useState(data?.data);
  const filteredData = useMemo(() => {
    return homeMeta.filter((item: any) => item.seo_fields.slug === null) || [];
  }, [homeMeta]);

  if (filteredData.length === 0) return <Add pathToAdd="/meta/home/create" />;

  return (
    <List aria-label="Page list">
      {filteredData.map((item: any) => {
        return (
          <>
            <HomeMetaCard
              homeMetaData={item}
              homeMeta={homeMeta}
              setHomeMeta={setHomeMeta}
            />
            <Divider />
          </>
        );
      })}
    </List>
  );
};
