import { useState, useCallback } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Collapse from "@mui/material/Collapse";

import { AllNews } from "./AllNews";
import { AllBases } from "./AllBases";
import { AllPrograms } from "./AllPrograms";

export const Pages = () => {
  const [open, setOpen] = useState(true);
  const handleToggle = useCallback(() => setOpen((open) => !open), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <div>
      <ListItemButton id="demo-customized-button" onClick={handleToggle}>
        <ListItemIcon>
          <FormatListBulletedIcon />
        </ListItemIcon>
        <ListItemText primary="Pages" />
        <KeyboardArrowDownIcon />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ClickAwayListener onClickAway={handleClose}>
          <List component="div" disablePadding sx={{ pl: 4 }}>
            <AllNews />
            <AllBases />
            <AllPrograms />
          </List>
        </ClickAwayListener>
      </Collapse>
    </div>
  );
};
