import { FC, useMemo, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";

import { BasesMetaCard } from "./bases-meta-card";
import { Add } from "common/AllPages/actions/add";

export interface DisplayProps {
  data: any;
}

export const Display: FC<DisplayProps> = ({ data }) => {
  const [basesMeta, setBasesMeta] = useState(data?.data);
  const filteredData = useMemo(() => {
    return basesMeta.filter((item: any) => item.seo_fields.slug === "knowledge-bases") || [];
  }, [basesMeta]);

  if (filteredData.length === 0) return <Add pathToAdd="/meta/bases/create" />;

  return (
    <List aria-label="Page list">
      {filteredData.map((item: any) => {
        return (
          <>
            <BasesMetaCard
              basesMetaData={item}
              basesMeta={basesMeta}
              setBasesMeta={setBasesMeta}
            />
            <Divider />
          </>
        );
      })}
    </List>
  );
};
