import { FC } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import { Show } from "./show";
import { Edit } from "./edit";

export interface FaqFieldProps {
  faqs: any;
  onHandleSaveFaqs: (index: number, title: string, description: string) => void;
  onHandleDeleteFaqs: (index: number) => void;
  onHandleAddFaqs: () => void;
  onHandleShowAddFaqItemButton: (state: boolean) => void;
  onHandleCurrentIndexFaqs: (index: number | null) => void;
  currentIndexFaqs: any;
  showAddFaqItemButton: any;
}

export const FaqField: FC<FaqFieldProps> = ({
  faqs,
  onHandleSaveFaqs,
  onHandleDeleteFaqs,
  onHandleAddFaqs,
  onHandleShowAddFaqItemButton,
  onHandleCurrentIndexFaqs,
  currentIndexFaqs,
  showAddFaqItemButton,
}) => {
  return (
    <div>
      {faqs.map((item: any, index: number) =>
        currentIndexFaqs === index ? (
          <Edit
            data={item}
            index={index}
            onHandleShowAddFaqItemButton={onHandleShowAddFaqItemButton}
            onHandleCurrentIndexFaqs={onHandleCurrentIndexFaqs}
            onHandleSave={onHandleSaveFaqs}
            onHandleDelete={onHandleDeleteFaqs}
          />
        ) : (
          <Show
            data={item}
            index={index}
            onHandleCurrentIndexFaqs={onHandleCurrentIndexFaqs}
            onHandleDelete={onHandleDeleteFaqs}
          />
        ),
      )}
      <br />
      {!showAddFaqItemButton && (
        <Box>
          <Divider />
          <Stack
            spacing={2}
            direction="row"
            sx={{ justifyContent: "flex-end", padding: "20px 0" }}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={onHandleAddFaqs}
              color="success"
            >
              Add new
            </Button>
          </Stack>
        </Box>
      )}
    </div>
  );
};
