import { FC, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { set } from 'monolite'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { objectToFormData } from 'utils/objectToFormData'
import { useNewsMetaData } from '../hooks/useNewsMetaData'

import { useEditNewsMeta } from './hooks/useEditNewsMeta'
import { useFetchNewsMeta } from './hooks/useFetchNewsMeta'

import { initialDataSet } from '../initial-data-set'
import { STEPS } from '../configs'

import { DataSet } from '../types'

import { Container } from 'layout/Container'
import { DataProvider, Changer } from '../context'

export interface EditNewsMetaProps {}

export const EditNewsMeta: FC<EditNewsMetaProps> = () => {
  const { newsMetaId } = useParams<{ newsMetaId: string }>()
  const editNewsMeta = useEditNewsMeta()
  const [state, setState] = useState<DataSet>(initialDataSet)
  const newsMetaData = useNewsMetaData(state, true)
  
  const formData = objectToFormData(newsMetaData)

  const handleChange = useCallback<Changer['change']>((value: DataSet[keyof DataSet], slug: keyof DataSet) => {
    setState((state) => set(state, _ => _[slug], value))
  }, [])
  const data = useMemo<DataSet & Changer>(() => ({ ...state, change: handleChange }), [state, handleChange])

  useFetchNewsMeta(setState)

  const handleSave = useCallback(() => {
    /* @ts-ignore */
    editNewsMeta(formData, newsMetaId)
  }, [state])

  const [ ...forms ] = STEPS

  return (
    <DataProvider value={data}>
      <Container
        sx={{
          py: 3,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {forms.map(({ Component: Form, slug }, i) => (
              <Paper
                key={`Form#${slug}`}
                sx={{ p: 2, display: "flex", flexDirection: "column" }}
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Paper
                    variant="outlined"
                    sx={{
                      p: { xs: 2, md: 3 },
                      my: 1,
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    {/* @ts-ignore */}
                    <Form
                      value={state[slug]}
                      slug={slug}
                      onChange={handleChange}
                    />
                  </Paper>
                </Box>
              </Paper>
            ))}
            <Box display="flex" marginTop="auto">
              <Box px={2} pt={1} mt={3} ml="auto" mr={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  onClick={handleSave}
                  sx={{ width: 120, ml: 3 }}
                >
                  Publish
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </DataProvider>
  )
}
